import { ref, watchEffect, nextTick } from 'vue';

export const userMessage = ref('');
const userMessage2 = ref('');
export const messages = ref([]);
export const isSending = ref(false);

const clearMessages = () => {
  messages.value = [];
  messages.value.push({ role: 'system', content: "응답은 짧게한다. 실제 사람과 대화한다고 착각할 정도로 말을 자연스럽게 한다. 츤데레다." });
};
clearMessages()

export const sendMessage = async () => {
  if (isSending.value) return;

  // 메시지를 보내는 동안 버튼 비활성화
  isSending.value = true;

  // 사용자 입력 메시지를 추가
  if( userMessage2.value !== '' ){
    messages.value.push({ role: 'user', content: [
        {
          'type': 'text',
          'text': userMessage.value
        },
        {
          'type': 'image_url',
          'image_url': {
            'url': userMessage2.value
          }
        }
      ]
    });
    userMessage2.value = '';
  }else{
    messages.value.push({ role: 'user', content: userMessage.value });
  }
  await nextTick(scrollToBottom);

  try {
    const response = await fetch('https://pro.mel0ng.kr/api.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        apiName: 'getOpenAI',
        msg: messages.value,
      })
    });

    if(response) {
      const data = await response.json();
      data.choices[0].message.content = data.choices[0].message.content
                                                                .replaceAll(/```([^```]*)```/g, '<xmp>$1</xmp>')
                                                                .replaceAll(/\*\*([^*]*)\*\*/g, '<b>$1</b>')
                                                                .replaceAll(/^### (.+)$/gm, '<h3>$1</h3>')
                                                                .replaceAll(/^## (.+)$/gm, '<h2>$1</h2>')
                                                                .replaceAll(/^# (.+)$/gm, '<h1>$1</h1>')
                                                                .replaceAll(/^- (.+)$/gm, '<li>$1</li>')
                                                                .replaceAll(/^\d+\. (.+)$/gm, '<li>$1</li>')
      const aiResponse = data.choices[0].message.content;

      //push
      if (typeof data.function_data !== 'undefined' && data.function_data.functionName === 'reminder') {
        window.Android.scheduleNotification(data.function_data.title, data.function_data.message, data.function_data.time);
      }

      messages.value.push({role: 'assistant', content: `${aiResponse}`});
      nextTick(scrollToBottom);
    }else{
      messages.value.push({role: 'assistant', content: `응? 뭐라고?`});
      nextTick(scrollToBottom);
    }
  } catch (error) {
    console.error('Error:', error);
    messages.value.push({role: 'assistant', content: error});
  }

  userMessage.value = '';
  isSending.value = false;
};
export const addMessage = (newMsg) => {
  userMessage2.value = newMsg;
};
const scrollToBottom = () => {
  let chatBody = document.getElementsByClassName('chat-body');
  chatBody[0].scrollTo({ top: chatBody[0].scrollHeight, behavior: 'smooth' });
  document.querySelector('.form-control')?.focus();
};
watchEffect(() => {
  nextTick(scrollToBottom);
});